.container {
  height: 100svh;
  margin: 0 auto;
  max-width: 480px;
  overflow: hidden;
  display: flex;
  position: relative;
  justify-content: center;
}

.btns_section {
  position: absolute;
  bottom: 40px;
}

.video {
  object-fit: cover;
  /* width: auto;
  height: 100%;
  position: fixed; */
}
