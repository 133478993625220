.container {
  height: 100svh;
  margin: 0 auto;
  max-width: 480px;
  overflow: hidden;
  background-image: url('./bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.report_section {
  background-image: url('./figure.png');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.btns_section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 40px;
  text-align: center;
  height: 100%;
}

.head_description {
  text-align: center;
  margin: 10px 20px;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background: rgba(232, 156, 174, 0.8);
  text-decoration: underline;
  text-decoration-color: #f5690d;
  margin-bottom: 10px;
}