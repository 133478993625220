@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";



body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:not(code) {
  font-family: "Work Sans" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.react-pdf__Page__textContent , .react-pdf__Page__annotations {
  display : none;
}

#ResumeContainer {
  margin:auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFDocument {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFPage {
  /* box-shadow: 0 0 8px rgba(0, 0, 0, .5); */
}

.PDFPageOne {
  /* margin-bottom: 25px; */
}

.PDFPage > canvas {
  max-width: 100%;
  height: auto !important;
}